import debounce from 'lodash.debounce';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { color, mq, space } from '../../stylesheets';
import Stack from '../Stack';

import Text from '../Text';

const FooterPlaceholder = styled.div<{ $height: string }>`
  height: ${props => props.$height};

  @media print {
    display: none;
  }
`;

const FooterFixed = styled.footer<{
  $position: string;
}>`
  width: 100%;
  overflow: hidden;
  bottom: 0px;
  left: 0px;
  background: ${props => color('primary', 90)};
  color: ${color('neutral', 10)};
  padding: ${space(['xxl', 'm'])};
  align-items: flex-end;
  display: flex;
  position: ${props => props.$position};

  @media print {
    display: none;
  }
`;

const Logo = styled.img`
  height: 55px;
  width: auto;
  margin: 0;
  display: block;

  ${mq('m')} {
    height: 80px;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1400px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${space('xxl')};
  margin: 0 auto;

  ${mq('m')} {
    grid-template-columns: 1fr 1fr;
  }
`;

const LogoList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${space('m')};

  ${mq('m')} {
    gap: ${space('xl')};
  }
`;

const FundedByWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space('m')};

  ${mq('m')} {
    align-self: end;
    justify-self: end;
  }
`;

const Footer = () => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState('60vh');
  const [position, setPosition] = useState('fixed');

  const updateFooterHeight = useCallback(() => {
    if (!ref.current) return;
    const { height } = ref.current.getBoundingClientRect();
    setPosition(height > window.innerHeight ? 'relative' : 'fixed');
    setHeight(`${height}px`);
  }, []);

  useEffect(() => {
    updateFooterHeight();
    window.addEventListener('resize', debounce(updateFooterHeight, 200));
    return () => window.removeEventListener('resize', debounce(updateFooterHeight, 200));
  }, [updateFooterHeight]);

  return (
    <>
      {position === 'fixed' && <FooterPlaceholder $height={height} />}

      <FooterFixed ref={ref} $position={position}>
        <ContentWrapper>
          <Stack gap="xxl">
            <Stack gap="m">
              <Text variant="headline-3" transform="uppercase">
                Shoah Memorial Frankfurt
              </Text>

              <Text variant="copy-large">{t('infoLayer.copy')}</Text>
            </Stack>

            <LogoList>
              <a
                href="https://www.juedischesmuseum.de/besuch/museum-judengasse/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Logo src="/museum-judengasse-logo.svg" alt="Museum Judengasse Logo" />
              </a>

              <a href="https://frankfurt-und-der-ns.de/" target="_blank" rel="noopener noreferrer">
                <Logo src="/fudns-logo.svg" alt="Frankfurt und der Nationalsozialismus Logo" />
              </a>
            </LogoList>
          </Stack>

          <FundedByWrapper>
            <Text variant="copy-small">{t('footer.fundedBy')}</Text>

            <LogoList>
              <a
                href="https://www.bundesfinanzministerium.de/Web/DE/Home/home.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Logo
                  src={`/bundesministerium-der-finanzen-${locale}.png`}
                  alt="Bundesministerium der Finanzen Logo"
                />
              </a>
              <a
                href="https://www.stiftung-evz.de/was-wir-foerdern/drittmittel-programme/bildungsagenda-ns-unrecht/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Logo src={`/evz-${locale}.png`} alt="EVZ Logo" />
              </a>
            </LogoList>
          </FundedByWrapper>
        </ContentWrapper>
      </FooterFixed>
    </>
  );
};

export default Footer;
