import useTranslation from 'next-translate/useTranslation';
import { ReactNode, useEffect } from 'react';
// @ts-ignore
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import {
  OverlayContentWrapper,
  OverlayContent,
  ShimLayer,
  OverlayWrapper,
  OverlayHeaderContent,
  OverlayHeader,
  Loading,
  OverlayButtons
} from './Overlay.style';

import Button from '../Button';
import Spinner from '../Spinner';
import useFocusTrap from '../../hooks/useFocusTrap';

type OverlayProps = {
  children: ReactNode;
  onClose: () => void;
  loading?: boolean;
  id?: string;
  size?: 'narrow' | 'default';
  buttons?: ReactNode;
  header?: ReactNode;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  delay?: number;
};

const Overlay = ({
  children,
  onClose,
  loading,
  size = 'default',
  header,
  id,
  buttons,
  delay,
  ariaLabelledBy,
  ariaDescribedBy
}: OverlayProps) => {
  const { t } = useTranslation('common');

  const { ref } = useFocusTrap<HTMLDivElement>({
    onKeyDown: e => {
      if (e.keyCode === 27) return onClose();
    }
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      e.preventDefault();
      onClose();
    }
  };

  useEffect(() => {
    if (ref.current) disableBodyScroll(ref.current);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      if (ref.current) enableBodyScroll(ref.current);
      clearAllBodyScrollLocks();
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollTo({ top: 0 });
  }, [id]);

  return (
    <OverlayWrapper>
      <ShimLayer
        onClick={onClose}
        type="button"
        key="overlay-shim"
        aria-label={t('labels.close')}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: 'easeInOut', duration: 0.6, delay }}
      />

      <OverlayContentWrapper
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        exit={{ x: '100%' }}
        transition={{ ease: 'easeInOut', duration: 0.6, delay }}
        key="overlay-content"
        ref={ref}
        $size={size}
        aria-modal="true"
        aria-labelledby={ariaLabelledBy}
        aria-describedby={ariaDescribedBy}
      >
        <OverlayHeader>
          {header && <OverlayHeaderContent>{header}</OverlayHeaderContent>}

          <OverlayButtons>
            <Button
              label={t('labels.close')}
              iconPosition="only"
              icon="Close"
              variant="tertiary"
              onClick={onClose}
            />

            {buttons}
          </OverlayButtons>
        </OverlayHeader>

        <OverlayContent>
          {loading ? (
            <Loading>
              <Spinner />
            </Loading>
          ) : (
            children
          )}
        </OverlayContent>
      </OverlayContentWrapper>
    </OverlayWrapper>
  );
};

export default Overlay;
