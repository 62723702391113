import styled from 'styled-components';
import { space } from '../../stylesheets';
import { Spacings } from '../../tokens';

const Stack = styled.div<{ gap: Spacings }>`
  & > * + * {
    margin-top: ${props => space(props.gap)} !important;
  }
`;

export default Stack;
