import styled, { css } from 'styled-components';

import { btnReset, space, color, variant } from '../../stylesheets';
import { ButtonProps } from './Button';

export type StyledButtonProps = {
  $iconPosition: ButtonProps['iconPosition'];
  $variant: ButtonProps['variant'];
  as: ButtonProps['as'];
};

const StyledButton = styled.button<StyledButtonProps>`
  ${btnReset()}
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 2px;
  height: 40px;
  padding: 0 ${space('xs')};
  transition: color 200ms, background-color 200ms, border-color 200ms;

  &:disabled {
    pointer-events: none;
    user-select: none;
    color: ${color('neutral', 30)};
  }

  svg {
    color: currentColor;
    fill: currentColor;
    flex-shrink: 0;
  }

  /* variant Prop */
  ${variant({
    prop: '$variant',
    primary: css`
      border-color: ${color('primary')};
      background: ${color('primary')};
      color: ${color('neutral', 10)};

      &:hover {
        background: ${color('primary')};
        border-color: ${color('primary')};
      }

      &:focus {
        background: ${color('primary')};
      }

      &:active {
        background: ${color('primary', 90)};
        border-color: ${color('primary', 90)};
      }

      &:disabled {
        color: ${color('neutral', 50)};
        background: ${color('neutral', 30)};
        border-color: ${color('neutral', 30)};
      }
    `,
    secondary: css`
      background: transparent;
      color: ${color('primary')};
      border-color: ${color('primary')};

      &:hover {
        color: ${color('neutral', 10)};
        border-color: ${color('primary')};
        background: ${color('primary')};
      }

      &:active {
        color: ${color('neutral', 10)};
        border-color: ${color('primary')};
        background: ${color('primary')};
      }

      &:disabled {
        background: transparent;
        color: ${color('neutral', 30)};
        border-color: ${color('neutral', 30)};
      }
    `,
    tertiary: css`
      border-color: transparent;
      background: transparent;

      &:hover,
      &:focus {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 3px;
      }
    `
  })}

  /* iconPosition Prop */
  ${variant({
    prop: '$iconPosition',
    after: css`
      flex-direction: row-reverse;

      svg:not(:only-child) {
        margin-left: ${space('xxs')};
      }
    `,
    before: css`
      svg:not(:only-child) {
        margin-right: ${space('xxs')};
      }
    `,
    only: css`
      svg:not(:only-child) {
        margin-right: ${space('xxs')};
      }
    `
  })};
`;

export default StyledButton;
