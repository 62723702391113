import styled, { css, keyframes } from 'styled-components';
import { motion } from 'framer-motion';

import { color, mq, removeScrollbars, space, variant } from '../../stylesheets';

const boxShadow = keyframes`
  from {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
  }

  to {
    box-shadow: 0 0 500px 0px rgba(0, 0, 0, 0.3);
  }
`;

export const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 991;

  @media print {
    position: static;
    height: auto;
    overflow: visible;
  }
`;

export const ShimLayer = styled(motion.button as any)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(248, 245, 242, 0.2);
  backdrop-filter: blur(3px);
`;

export const OverlayContentWrapper = styled(motion.div as any)<{ $size: 'narrow' | 'default' }>`
  position: absolute;
  top: 0;
  right: 0;
  background: ${color('base')};
  width: 100%;
  max-width: 1280px;
  height: 100%;
  overflow: scroll;
  scroll-padding-top: 100px;
  animation: ${boxShadow} 0.6s ease-in-out forwards;

  @media print {
    position: static;
    height: auto;
    overflow: visible;
    background: none;
  }

  ${variant({
    prop: '$size',
    narrow: css`
      max-width: 500px;
    `
  })}
`;

export const OverlayContent = styled.div`
  /* overflow: hidden; */
`;

export const OverlayButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const OverlayHeaderContent = styled.div`
  flex-grow: 2;
  overflow: scroll;
  ${removeScrollbars}
`;

export const OverlayHeader = styled.header`
  position: sticky;
  top: 0;
  background: ${color('neutral', 10)};
  height: ${space('xl')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  z-index: 990;
  padding: 0 ${space('s')};

  @media print {
    display: none;
  }

  ${mq('m')} {
    background: transparent;
    height: ${space('xxl')};
  }
`;

export const Loading = styled.div`
  width: 100%;
  height: calc(100vh - ${space('xl')});
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${color('base')};

  ${mq('m')} {
    height: calc(100vh - ${space('xxl')});
  }
`;
