import { AnimatePresence } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import LanguageSwitch from '../LanguageSwitch';
import Button from '../Button';
import Navigation from '../Navigation';
import Overlay from '../Overlay';
import Text from '../Text';

import { color, mq, space } from '../../stylesheets';

const StyledHeader = styled.header`
  position: relative;
  height: ${space('xl')};

  ${mq('m')} {
    height: ${space('xxl')};
  }

  @media print {
    display: none;
  }
`;

const FixedHeader = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 990;
  background: ${color('neutral', 10)};
  padding: ${space('s')};
  justify-content: space-between;
  height: ${space('xl')};

  ${mq('m')} {
    display: grid;
    height: ${space('xxl')};
    padding: ${space(['xxs', 'm'])};
    grid-template-columns: 1fr 2fr 1fr;
  }
`;

const LeftHeader = styled.div`
  justify-self: flex-start;
  display: none;

  ${mq('m')} {
    grid-column: 1 / span 1;
    display: block;
  }
`;

const RightHeader = styled.div`
  justify-self: flex-end;
  display: flex;
  align-items: center;

  ${mq('m')} {
    grid-column: 3 / span 1;
  }
`;

const HeaderLinks = styled.div`
  align-items: center;
  display: flex;
`;

const Logo = styled.button`
  cursor: pointer;
  justify-self: center;
  grid-area: center;
  grid-column: 2 / span 1;
  grid-row: 1;
  transition: color 200ms, background-color 200ms, border-color 200ms;
`;

const Header = () => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    router.events.on('routeChangeComplete', () => setIsOpen(false));
    return () => router.events.off('routeChangeComplete', () => setIsOpen(false));
  });

  return (
    <>
      <StyledHeader>
        <FixedHeader>
          <Logo type="button" onClick={() => router.push('/')}>
            <Text variant="headline-4" as="span" transform="uppercase">
              Shoah Memorial Frankfurt
            </Text>
          </Logo>

          <RightHeader>
            <HeaderLinks>
              <LanguageSwitch />
            </HeaderLinks>

            <Button
              label={t('labels.menu')}
              icon={isOpen ? 'Close' : 'Menu'}
              iconPosition="only"
              variant="tertiary"
              onClick={() => setIsOpen(prev => !prev)}
            />
          </RightHeader>
        </FixedHeader>
      </StyledHeader>

      <AnimatePresence>
        {isOpen && (
          <Overlay onClose={() => setIsOpen(false)} size="narrow">
            <Navigation />
          </Overlay>
        )}
      </AnimatePresence>
    </>
  );
};

export default Header;
