import { useCallback, useEffect, useRef } from 'react';

export type UseFocusTrapOptions = {
  initialFocusRef?: React.RefObject<HTMLElement> | null;
  onKeyDown?: (e: any) => void;
};

const useFocusTrap = <T>(options?: UseFocusTrapOptions): { ref: React.RefObject<T> } => {
  const { initialFocusRef, onKeyDown } = options || {
    initialFocusRef: undefined,
    onKeyDown: undefined
  };

  const ref = useRef<T>(null);
  const prevFocus = useRef(
    typeof document !== 'undefined' ? (document.activeElement as HTMLElement | null) : null
  );

  const getFocusElements = useCallback(() => {
    const elements = [
      // @ts-ignore
      ...ref.current?.querySelectorAll(
        'a[href], button:not([disabled]), textarea, input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select, [tabindex]:not([tabindex="-1"])'
      )
    ].filter(el => {
      return (
        !el.hasAttribute('disabled') &&
        !el.getAttribute('aria-hidden') &&
        window.getComputedStyle(el).display !== 'none'
      );
    });

    const first = elements && elements.length > 0 ? elements[0] : null;
    const last = elements && elements.length > 0 ? elements[elements.length - 1] : null;

    return [first, last];
  }, [ref]);

  const handleTabKey = useCallback((e: any) => {
    const [first, last] = getFocusElements();
    if (!first && !last) return e.preventDefault();

    if (!e.shiftKey && document.activeElement === last) {
      first?.focus();
      return e.preventDefault();
    }

    if (e.shiftKey && document.activeElement === first) {
      last?.focus();
      e.preventDefault();
    }
  }, []);

  const handleArrowKeys = useCallback((e: any) => {
    const activeElement = document.activeElement;
    const inputs = ['input', 'select', 'textarea'];

    if (activeElement && inputs.includes(activeElement.tagName.toLowerCase())) {
      return e;
    }

    const elements: (HTMLAnchorElement | HTMLButtonElement)[] = Array.from(
      // @ts-ignore
      ref.current?.querySelectorAll('a[href], button:not([disabled])')
    );
    const activeIndex = activeElement ? elements?.indexOf(activeElement as any) : 0;

    // If is keydown
    if (e.keyCode === 40) {
      if (activeIndex === -1 || activeIndex === elements.length - 1) {
        return elements[0].focus();
      }
      return elements[activeIndex + 1].focus();
    }

    if (e.keyCode === 38) {
      if (activeIndex === -1 || activeIndex === 0) {
        return elements[elements.length - 1].focus();
      }
      return elements[activeIndex - 1].focus();
    }
  }, []);

  const handleKeyDown = useCallback((e: any) => {
    if (onKeyDown) onKeyDown(e);
    if (e.keyCode === 9) return handleTabKey(e);
    if (e.keyCode === 40 || e.keyCode === 38) return handleArrowKeys(e);
  }, []);

  useEffect(() => {
    if (initialFocusRef && initialFocusRef.current) {
      return initialFocusRef.current.focus();
    }

    const [first] = getFocusElements();
    first?.focus();

    return () => {
      prevFocus.current && prevFocus.current.focus ? prevFocus.current.focus() : null;
    };
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  return { ref };
};

export default useFocusTrap;
