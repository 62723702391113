import styled, { css } from 'styled-components';
import { variant } from '../../stylesheets';

const StyledSpinner = styled.div<{ $size: 'small' | 'default' }>`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  svg {
    stroke-width: 3px;
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    stroke-linecap: round;
    animation: dash 1.5s linear forwards infinite;
  }

  ${variant({
    prop: '$size',
    small: css`
      width: 30px;
      height: 30px;
    `
  })}

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

type SpinnerProps = { size?: 'small' | 'default' };

const Spinner = ({ size = 'default' }: SpinnerProps) => {
  const sizes = {
    default: '80px',
    small: '30px'
  };

  return (
    <StyledSpinner $size={size}>
      <svg
        width={sizes[size]}
        height={sizes[size]}
        viewBox="0 0 84 84"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          fillRule="evenodd"
        >
          <path d="M15.995 68.336 53.558 82 40.076 42 4.364 43.182z" />
          <path d="M47.643 2 80 23.303l-9.69 45.613L53.558 82 40.076 42z" />
          <path d="M4.364 43.182 47.643 2l-7.567 40z" />
        </g>
      </svg>
    </StyledSpinner>
  );
};

export default Spinner;
