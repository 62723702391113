import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import Link from 'next/link';

import Text from '../Text';

import { mq, space } from '../../stylesheets';
import { useRouter } from 'next/router';

const StyledNavigation = styled.nav`
  padding: ${space('m')};
  min-height: calc(100vh - ${space('xl')});
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mq('m')} {
    padding: ${space('xxl')};
    min-height: calc(100vh - ${space('xxl')});
  }

  a:hover,
  a.current {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
  }
`;

const MainLinks = styled.ul`
  & > li + li {
    margin-top: ${space('m')};
  }
`;

const SubLinks = styled.ul`
  & > li + li {
    margin-top: ${space('xs')};
  }
`;

const Navigation = () => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const path = router.asPath.split('?')[0];

  const slugs: { [key: string]: any } = {
    en: {
      about: 'about-this-site',
      aboutMemorial: 'boerneplatz-memorial',
      aboutShoah: 'the-shoah-in-frankfurt'
      // education: 'education',
      // contact: 'contact'
    },
    de: {
      about: 'ueber-diese-website',
      aboutMemorial: 'gedenkstaette-neuer-boerneplatz',
      aboutShoah: 'shoah-in-frankfurt'
      // education: 'bildung-und-vermittlung',
      // contact: 'kontakt'
    }
  };

  const generalSlugs: { [key: string]: any } = {
    en: {
      imprint: 'imprint',
      privacyPolicy: 'privacy-policy',
      cookiePolicy: 'cookie-policy'
    },
    de: {
      imprint: 'impressum',
      privacyPolicy: 'datenschutz',
      cookiePolicy: 'cookie-policy'
    }
  };

  const links = slugs[router.locale || 'de'];
  const generalLinks = generalSlugs[router.locale || 'de'];

  return (
    <StyledNavigation>
      <MainLinks>
        <li>
          <Link href="/" locale={router.locale} passHref legacyBehavior>
            <Text
              weight="medium"
              variant="headline-4"
              as="a"
              className={path === '/' ? 'current' : ''}
            >
              {t('menu.memorial')}
            </Text>
          </Link>
        </li>

        <li>
          <Link href="/search" locale={router.locale} passHref legacyBehavior>
            <Text
              weight="medium"
              variant="headline-4"
              as="a"
              className={path === '/search' ? 'current' : ''}
            >
              {t('menu.search')}
            </Text>
          </Link>
        </li>

        {Object.keys(links).map(i => {
          const href = `/${links[i]}`;

          return (
            <li key={i}>
              <a href={`/${router.locale}${href}`}>
                <Text
                  weight="medium"
                  variant="headline-4"
                  as="a"
                  className={path === href ? 'current' : ''}
                >
                  {t(`menu.${i}`)}
                </Text>
              </a>
            </li>
          );
        })}
      </MainLinks>

      <SubLinks>
        {Object.keys(generalLinks).map(i => {
          const href = `/${generalLinks[i]}`;

          return (
            <li key={i}>
              <a href={`/${router.locale}${href}`}>
                <Text weight="medium" as="a" className={path === href ? 'current' : ''}>
                  {t(`menu.${i}`)}
                </Text>
              </a>
            </li>
          );
        })}
      </SubLinks>
    </StyledNavigation>
  );
};

export default Navigation;
