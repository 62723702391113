import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import Text from '../Text';

import { space } from '../../stylesheets';

const StyledLanguageSwitch = styled.div`
  display: flex;
  align-items: center;
  margin: 0 calc(${space('xxs')} * -1);

  & > * {
    position: relative;
    padding: ${space('xxs')};

    & + *:before {
      position: absolute;
      content: '/';
      left: calc(${space('xs')} / 2 * -1);
      top: 50%;
      transform: translateY(calc(-50% - 1px));
    }
  }

  a:hover,
  a:focus {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
  }
`;

const LanguageSwitch = () => {
  const router = useRouter();

  const otherLocale = router.locale === 'en' ? 'de' : 'en';

  return (
    <StyledLanguageSwitch>
      <Link href="/" locale={otherLocale} passHref legacyBehavior>
        <a>
          <Text weight="medium" color="primary" transform="uppercase" variant="button">
            {otherLocale}
          </Text>
        </a>
      </Link>
    </StyledLanguageSwitch>
  );
};

export default LanguageSwitch;
